import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import classes from "./scss/Common.module.scss";
import Trash from "../../assets/deleteTrash.svg"
interface PopupDialogBoxProps {
  deleteDialog: boolean;
  handleDeleteClose: () => void;
  handleDeleteVideo: () => void;
}

const DeleteAlertBox: React.FC<PopupDialogBoxProps> = ({
  deleteDialog,
  handleDeleteClose,
  handleDeleteVideo,
}) => {
  return (
    <Dialog
      open={deleteDialog}
      onClose={handleDeleteClose}
      sx={{
        "& .MuiPaper-root": { borderRadius: "0.83vw", maxWidth: "61.80vw" },
      }}
    >
      <DialogContent className={classes.deleteAlertDialogContainer}>
        <img src={Trash} alt="Trash" className={classes.deleteLogo}/>
        <h1 className={classes.deleteWarningText}>Warning!!!</h1>
        <p className={classes.deleteWarningSubText}>
          You are about to delete this video.Are you sure you want to remove it
          from the product feed?
        </p>
        <input
          type="submit"
          value="Cancel"
          className={classes.deleteCancelButton}
          onClick={handleDeleteClose}
        />
        <input
          type="submit"
          value="Confirm"
          className={classes.deleteConfirmButton}
          onClick={handleDeleteVideo}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DeleteAlertBox;
