import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../../../../app/index";
import { RootState } from "../../../../../app/store";
import { SetStateAction } from "react";
export interface StoriesPageSliceState {
  products: any;
  status: "idle" | "loading" | "failed";
}

const initialState: StoriesPageSliceState = {
  products: null,
  status: "idle",
};

export const getProducts = createAsyncThunk(
  "widget/product",
  async ({
    store_id,
    setLoading,
    setTotal,
    skip = 0,
    setSkip,
  }: {
    store_id: string;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    setTotal?: React.Dispatch<React.SetStateAction<number>>;
    skip?: number;
    setSkip?: React.Dispatch<SetStateAction<number>>;
  }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/widget/product/?store=${store_id}&limit=20&skip=${skip}`;
    setLoading && setLoading(true);
    try {
      const response = await API.get(url);
      if (response.status === 200) {
        setLoading && setLoading(false);
        setSkip && setSkip((p) => p + 20);
        if (setTotal) {
          setTotal(response.data.count);
        }
        return response.data;
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const getQueryProducts = createAsyncThunk(
  "widget/searchproduct",
  async ({
    store_id,
    title,
    setLoading,
  }: {
    store_id: string;
    title: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/widget/product/?store=${store_id}&title=${title}`;
    setLoading && setLoading(true);
    try {
      const response = await API.get(url);
      if (response.status === 200) {
        setLoading && setLoading(false);
        return response.data;
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const storiesPageSliceSlice = createSlice({
  name: "storiesPageSlice",
  initialState,
  reducers: {
    getProducts: (state: StoriesPageSliceState, action: PayloadAction) => {
      state.products = action.payload;
    },
    getQueryProducts: (state: StoriesPageSliceState, action: PayloadAction) => {
      state.products = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.status = "idle";
        state.products = action.payload;
      })
      .addCase(getProducts.rejected, (state) => {
        state.status = "failed";
      });
    builder
      .addCase(getQueryProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getQueryProducts.fulfilled, (state, action) => {
        state.status = "idle";
        state.products = action.payload;
      })
      .addCase(getQueryProducts.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectStoriesPageSliceState = (state: RootState) =>
  state.productStore;
export default storiesPageSliceSlice.reducer;
