import * as React from "react";
import {
  Box,
  AppBar,
  CssBaseline,
  Drawer,
  List,
  Tabs,
  Tab,
} from "@mui/material";
import SideBar from "./SIdeBar";
import classes from "./Dashboard.module.scss";
import PopupDialogBox from "../../../utils/PopupDailogBox";
import StoriesPage from "../../Dashboard/StoriesPage";

interface AdminHocProps {
  children: React.ReactElement;
}

function DashboardHoc({ children }: AdminHocProps): JSX.Element {
  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState("all");
  const [page, setPage] = React.useState("create_Story");
  const [selectedStory, setSelectedStory] = React.useState<string | null>(null);
  const [selectedProductId, setSelectedProductId] = React.useState(0);
  const [isCreateStory, setIsCreateStory] = React.useState<boolean>(false);
  const [click, setClick] = React.useState(false);
  let condition = "/dashboard/stories";
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedStory(null);
    setSelectedProductId(0);
    setPage("create_Story");
    setIsCreateStory(false);
  };

  const temp = JSON.parse(localStorage.getItem("statusInfo") || "{}");

  React.useEffect(() => {
    if (temp && click) {
      setValue(temp);
    }
    localStorage.removeItem("statusInfo");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [click]);

  const handleChange = (_e: any, value: any) => {
    setValue(value);
  };

  const drawer = (
    <List className={classes.dashboardSidebarContainer}>
      <SideBar />
    </List>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: "calc(100% - 4.16vw)" },
          ml: { sm: "4.16vw" },
        }}
        className={classes.topBarContainer}
        elevation={0}
      >
        <div className={classes.dashboardHeading}>Dashboard</div>
        {window.location.pathname === condition && (
          <Tabs
            TabIndicatorProps={{
              sx: {
                backgroundColor: "#8E3FF1FF",
                height: "3px",
                borderRadius: "5px",
              },
            }}
            value={value}
            onChange={handleChange}
            className={classes.headerMenu}
          >
            <Tab
              className={
                value === "all"
                  ? classes.TabItemActive
                  : classes.TabItemInactive
              }
              label="ALL STORIES"
              value="all"
            />
            <Tab
              className={
                value === "Live"
                  ? classes.TabItemActive
                  : classes.TabItemInactive
              }
              label="LIVE STORIES"
              value="Live"
            />
            <Tab
              className={
                value === "Draft"
                  ? classes.TabItemActive
                  : classes.TabItemInactive
              }
              label="PAUSED STORIES"
              value="Draft"
            />
          </Tabs>
        )}
        <input
          type="submit"
          value="+ Add Story"
          className={classes.addButton}
          onClick={handleOpen}
        />
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: "4.16vw" },
          flexShrink: { sm: 0 },
          background: "#FDFCFA26",
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
          }}
          className={classes.drawerContainer}
        >
          {drawer}
        </Drawer>
      </Box>
      <PopupDialogBox open={open} handleClose={handleClose}>
        <StoriesPage
          handleClose={handleClose}
          page={page}
          setPage={setPage}
          setSelectedStory={setSelectedStory}
          selectedStory={selectedStory}
          selectedProductId={selectedProductId}
          setSelectedProductId={setSelectedProductId}
          setIsCreateStory={setIsCreateStory}
          isCreateStory={isCreateStory}
        />
      </PopupDialogBox>
      {window.location.pathname === condition ? (
        <div className={classes.childContainer}>
          {React.cloneElement(children, { value, setValue, setClick })}
        </div>
      ) : (
        <div className={classes.childContainer}>
          {React.cloneElement(children, { setValue, setClick })}
        </div>
      )}
    </Box>
  );
}

export default DashboardHoc;
