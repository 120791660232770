import React, { useRef } from "react";
import { TableRow } from "@mui/material";
import Trash from "../../../assets/trash.svg";
import ThreeDot from "../../../assets/dots-three-vertical.svg";
import classes from "../scss/Common.module.scss";

type DataItem = {
  gif: any;
  thumbnailUploaded: boolean;
  thumbnailReplaced: boolean;
  thumbnailVideoUpload: boolean;
  thumbnailVideoReplace: boolean;
  thumbnailUrl: string;
  _id: string;
  name: string;
  title: string;
  use_case: string;
  deleted: boolean;
};

export const EditStoryTableRow = ({
  videos,
  setVideos,
  video,
  handleDragStart,
  handleDragOver,
  handleDrop,
  index,
  setSelectedVideo,
  handleFileChange,
  handleGifFileChange,
  handleDeleteOpen,
  disableRow,
  deleteId,
}: {
  videos: DataItem[];
  setVideos: React.Dispatch<React.SetStateAction<DataItem[]>>;
  video: any;
  handleDragStart: Function;
  handleDragOver: Function;
  handleDrop: Function;
  index: number;
  setSelectedVideo: React.Dispatch<React.SetStateAction<string | null>>;
  handleFileChange: any;
  handleGifFileChange: any;
  handleDeleteOpen: Function;
  disableRow: boolean;
  deleteId: any;
}) => {
  const vidRef = useRef<HTMLInputElement | null>(null);
  const thubmnailreplaceRef = useRef<HTMLInputElement | null>(null);
  const gifUploadRef = useRef<HTMLInputElement | null>(null);
  const gifReplaceRef = useRef<HTMLInputElement | null>(null);
  const handleUploadClick = (_id: string): void => {
    // Upload selected file to server
    vidRef!.current!.click();
    setSelectedVideo(_id);
  };
  const handleThumbnailReplaceClick = (_id: string): void => {
    // Upload selected file to server
    thubmnailreplaceRef!.current!.click();
    setSelectedVideo(_id);
  };
  const handleGifUploadClick = (_id: string): void => {
    // Upload selected file to server
    gifUploadRef!.current!.click();
    setSelectedVideo(_id);
  };

  const handleGifReplaceClick = (_id: string): void => {
    // Upload selected file to server
    gifReplaceRef!.current!.click();
    setSelectedVideo(_id);
  };
  const isRowDisabled = (_id: string) => {
    const value = deleteId.some((obj: any) => {
      return obj?._id === video?._id;
    });
    return value;
  };

  return (
    <TableRow
      className={
        isRowDisabled(video?._id) ? classes.disabled_row : classes.tableRowStyle
      }
      key={video._id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      draggable
      onDragStart={(e) => handleDragStart(e, index)}
      onDragOver={(e) => handleDragOver(e, index)}
      onDrop={(e) => handleDrop(e, index)}
    >
      <td className={classes.threeDotGroupContainer}>
        <img src={ThreeDot} alt="threedot" className={classes.leftIcon} />
        <img src={ThreeDot} alt="threedot" className={classes.rightIcon} />
      </td>
      <td className={classes.videoText}>{video?.title}</td>
      <td className={classes.videoTitleText}>
        <input
          type="text"
          value={video.newTitle}
          onChange={(e) => {
            let videoObj = { ...video, newTitle: e.target.value };
            let list = [...videos];
            list[index] = videoObj;
            setVideos(list);
          }}
        />
      </td>
      <td className={classes.videoUseCase}>
        <select
          onChange={(e) => {
            let videoObj = {
              ...video,
              newUse_Case: e.target.value,
            };
            let list = [...videos];
            list[index] = videoObj;
            setVideos(list);
          }}
          value={video.newUse_Case}
        >
          <option value="Unboxing">Unboxing</option>
          <option value="Specs">Specs</option>
          <option value="Expert Reviews">Expert Reviews</option>
          <option value="Customer Reviews">Customer Reviews</option>
        </select>
      </td>
      <td
        className={classes.uploadVideoThumbnailButton}
        style={{
          background: video.thumbnailUploaded ? "#149E2BFF" : "#8E3FF1FF",
        }}
      >
        <p onClick={() => handleUploadClick(video?._id)}>
          {video?.thumbnailUploaded ? "Uploaded" : "Browse"}
          <input
            hidden
            disabled={video.thumbnailUploaded}
            data-input-index={video._id}
            type="file"
            accept="image/*"
            ref={vidRef}
            onChange={handleFileChange}
          />
        </p>
      </td>
      {video.thumbnailUploaded ? (
        <td
          className={classes.VideoThumbnailReplaceButton}
          style={{
            background: video.thumbnailReplaced ? "#149E2BFF" : "#8E3FF1FF",
          }}
        >
          {video.thumbnailUploaded ? (
            <p onClick={() => handleThumbnailReplaceClick(video?._id)}>
              {video?.thumbnailReplaced ? "Replaced" : "Replace"}
              <input
                hidden
                disabled={!video.thumbnailUploaded}
                data-input-index={video._id}
                type="file"
                accept="image/*"
                ref={thubmnailreplaceRef}
                onChange={handleFileChange}
              />
            </p>
          ) : null}
        </td>
      ) : (
        <td
          className={classes.VideoThumbnailReplaceButton}
          style={{
            background: "rgb(211,211,211)",
          }}
        >
          <p onClick={() => handleThumbnailReplaceClick(video?._id)}>
            {video?.thumbnailReplaced ? "Replaced" : "Replace"}
            <input
              hidden
              disabled={!video.thumbnailUploaded}
              data-input-index={video._id}
              type="file"
              accept="image/*"
              ref={thubmnailreplaceRef}
              onChange={handleFileChange}
            />
          </p>
        </td>
      )}

      <td
        className={classes.uploadVideoGifButton}
        style={{
          background: video.thumbnailVideoUpload ? "#149E2BFF" : "#8E3FF1FF",
        }}
      >
        <p onClick={() => handleGifUploadClick(video?._id)}>
          {video?.thumbnailVideoUpload ? "Uploaded" : "Browse"}
          <input
            hidden
            disabled={video?.thumbnailVideoUpload}
            data-input-index={video._id}
            type="file"
            accept=".mp4,.mpe4,.mkv,.webm,.mov,.ogg,.ogv,.avi"
            ref={gifUploadRef}
            onChange={handleGifFileChange}
          />
        </p>
      </td>
      {video.thumbnailVideoUpload ? (
        <td
          className={classes.VideoGifReplaceButton}
          style={{
            background: video.thumbnailVideoReplace ? "#149E2BFF" : "#8E3FF1FF",
          }}
        >
          {video.thumbnailVideoUpload ? (
            <p onClick={() => handleGifReplaceClick(video?._id)}>
              {video?.thumbnailVideoReplace ? "Replaced" : "Replace"}
              <input
                hidden
                disabled={!video.thumbnailVideoUpload}
                data-input-index={video._id}
                type="file"
                accept=".mp4,.mpe4,.mkv,.webm,.mov,.ogg,.ogv,.avi"
                ref={gifReplaceRef}
                onChange={handleGifFileChange}
              />
            </p>
          ) : null}
        </td>
      ) : (
        <td
          className={classes.VideoGifReplaceButton}
          style={{
            background: "rgb(211,211,211)",
          }}
        >
          <p onClick={() => handleGifReplaceClick(video?._id)}>
            {video?.thumbnailVideoReplace ? "Replaced" : "Replace"}
            <input
              hidden
              disabled={!video.thumbnailVideoUpload}
              data-input-index={video._id}
              type="file"
              accept=".mp4,.mpe4,.mkv,.webm,.mov,.ogg,.ogv,.avi"
              ref={gifReplaceRef}
              onChange={handleGifFileChange}
            />
          </p>
        </td>
      )}
      <td>
        <img
          src={Trash}
          alt="delete"
          className={classes.deleteIcon}
          onClick={() => {
            handleDeleteOpen(video._id);
            let videoObj = {
              ...video,
              deleted: true,
            };
            let list = [...videos];
            list[index] = videoObj;
            setVideos(list);
          }}
        />
      </td>
    </TableRow>
  );
};
