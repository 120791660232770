import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import classes from "./scss/Common.module.scss";
import Draft from "../../assets/Draft.svg"
interface ExistStory {
  existStory: boolean;
  handleDeleteClose: () => void;
}

const ExistStoryAlert: React.FC<ExistStory> = ({
  existStory,
  handleDeleteClose,
}) => {
  return (
    <Dialog
      open={existStory}
      onClose={handleDeleteClose}
      sx={{
        "& .MuiPaper-root": { borderRadius: "0.83vw", maxWidth: "61.80vw" },
      }}
    >
      <DialogContent className={classes.deleteAlertDialogContainer}>
        <img src={Draft} alt="Alert" className={classes.deleteLogo} />
        <h1 className={classes.existStoryWarningHeading}> Hey !!!</h1>
        <p className={classes.existStoryWarningSubHeading}>
          There is already a story running on this product, please inactive it
          and come back to create new one ... 
        </p>
        <input
          type="submit"
          value="Okay"
          className={classes.existStoryWarningButton}
          onClick={handleDeleteClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ExistStoryAlert;
