import React, { Suspense } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import MasterBoundary from "../features/ErrorBoundaries";
import ScrollToTop from "../features/ScrollToTop";
// ========= Components =========
import * as LazyComponent from "./LazyLoaded";
import DashboardHoc from "../features/Hoc/Dashboard/DashboardHoc";

function RouteList(): JSX.Element {
  return (
    <Suspense fallback="loading...">
      <MasterBoundary>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/">
              <Route path="" element={<Navigate to="/signup" />} />
              <Route path="/signup" element={<LazyComponent.Signup />} />
            </Route>
            <Route path="/signin" element={<LazyComponent.Signin />} />
            <Route path="/dashboard">
              <Route path="" element={<Navigate to="/dashboard/home" />} />
              <Route
                path="/dashboard/home"
                element={
                  <DashboardHoc>
                    <LazyComponent.DashboardHome
                      setValue={(newValue: any) => newValue}
                      setClick={(newValue: any) => newValue}
                    />
                  </DashboardHoc>
                }
              />
              <Route
                path="/dashboard/stories"
                element={
                  <DashboardHoc>
                    <LazyComponent.DashboardStories
                      value=""
                      setValue={(newValue: any) => newValue}
                      setClick={(newValue: any) => newValue}
                    />
                  </DashboardHoc>
                }
              />
              <Route
                path="/dashboard/analytics"
                element={
                  <DashboardHoc>
                    <LazyComponent.DashboardAnalytics />
                  </DashboardHoc>
                }
              />
              <Route
                path="/dashboard/info"
                element={
                  <DashboardHoc>
                    <LazyComponent.DashboardInfo />
                  </DashboardHoc>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </MasterBoundary>
    </Suspense>
  );
}

export default RouteList;
