import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../app/store";
import { showToast } from "../../utils/ErrorMessage";
export interface UserState {
  login_user: any;
  signup_user: any;
  loading: boolean;
  status: "idle" | "loading" | "failed";
  error: any;
}

const initialState: UserState = {
  login_user: null,
  signup_user: null,
  loading: false,
  status: "idle",
  error: null,
};

export const login = createAsyncThunk(
  "user/login",
  async ({
    email,
    password,
    setLoading,
    navigate,
    handleDialogOpen,
  }: {
    email: string;
    password: string;
    setLoading: Function;
    navigate: any;
    handleDialogOpen: Function;
  }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/user/login`;
    try {
      const response = await axios.post(url, {
        username: email,
        passwordhash: password,
      });
      if (response.status === 200) {
        const { stores_linked } = response.data;
        if (stores_linked) {
          navigate("/dashboard");
        } else {
          handleDialogOpen();
        }
        localStorage.setItem("userInfo", JSON.stringify(response.data));
        showToast("success", "User Login Successfully");
        return response.data;
      }
    } catch (err) {
      console.log(err);
      showToast("error", "Invalid username or password");
    } finally {
      setLoading && setLoading(false);
    }
  }
);
export const signUp = createAsyncThunk(
  "user/signup",
  async ({
    name,
    email,
    password,
    navigate,
    handleOpen,
  }: {
    name: string;
    email: string;
    password: string;
    navigate: any;
    handleOpen: Function;
  }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/user/signup`;
    try {
      const response = await axios.post(url, {
        name,
        email,
        passwordhash: password,
      });
      if (response.status === 200) {
        localStorage.setItem("signupInfo", JSON.stringify(response.data));

        const { isVerified } = response.data.user;

        if (!isVerified) {
          showToast(
            "success",
            "User Created Successfully!!! Please Verify your email"
          );
          handleOpen();
        } else {
          console.log(response.data.message);
        }
        return response.data;
      }
    } catch (err: any) {
      console.log(err);
      showToast("error", err.response.data.message);
    }
  }
);

export const signUpOTP = createAsyncThunk(
  "user/verify-email",
  async ({
    email,
    otp,
    navigate,
  }: {
    email: string;
    otp: string;
    navigate: any;
  }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/auth/user/verify-email`;
    try {
      const response = await axios.post(url, { email, otp });
      // The value we return becomes the `fulfilled` action payload
      if (response.status === 200) {
        showToast("success", "Email verified successfully");
        navigate("/signin");
      }
      return response.data;
    } catch (err) {
      console.log(err);
      showToast("error", "Please Enter a valid OTP");
    }
  }
);
export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { dispatch }) => {
    // Dispatch the logout action to the Redux store
    dispatch(logout());
    localStorage.removeItem("userInfo");
    // Return a resolved promise to indicate that the logout process is complete
    showToast("success", "User Logout successfully");
    return Promise.resolve();
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state: UserState, action: PayloadAction) => {
      state.login_user = action.payload;
    },
    register: (state: UserState, action: PayloadAction) => {
      state.signup_user = action.payload;
    },
    logout: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "idle";
        state.login_user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(signUp.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.error = null;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "idle";
        state.signup_user = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.error.message;
      });
    builder
      .addCase(signUpOTP.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.error = null;
      })
      .addCase(signUpOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "idle";
        state.signup_user = action.payload;
      })
      .addCase(signUpOTP.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectUser = (state: RootState): any => state.userStore;
export const { logout } = authSlice.actions;
export default authSlice.reducer;
