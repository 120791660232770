import React from 'react'

export const Signup = React.lazy(
  async () => await import('../features/Login/Signup')
)

export const Signin = React.lazy(
  async () => await import('../features/Login/Signin')
)

export const DashboardHome = React.lazy(
  async () => await import('../features/templates/DashboardHomeTemplate')
)

export const DashboardStories = React.lazy(
  async () => await import('../features/templates/DashboardStoriesTemplate')
)

export const DashboardAnalytics = React.lazy(
  async () => await import('../features/templates/DashboardAnalyticsTemplate')
)

export const DashboardInfo = React.lazy(
  async () => await import('../features/templates/DashboardInfoTemplate')
)