import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import classes from "../utils/Utils.module.scss";

interface PopupDialogBoxProps {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
}

const PopupDialogBox: React.FC<PopupDialogBoxProps> = ({
  open,
  handleClose,
  children,
}) => {
  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiPaper-root": { borderRadius: "0.83vw", maxWidth: "61.80vw" },
      }}
    >
      <DialogContent className={classes.StoryDialogContent}>{children}</DialogContent>
    </Dialog>
  );
};

export default PopupDialogBox;
