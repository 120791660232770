import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import classes from "./scss/Common.module.scss";
import Logout from "../../assets/Logout.svg";

interface PopupDialogBoxProps {
  logoutDialog: boolean;
  handleLogoutClose: () => void;
  handleLogout: () => void;
}

const LogoutAlert: React.FC<PopupDialogBoxProps> = ({
  logoutDialog,
  handleLogoutClose,
  handleLogout,
}) => {
  return (
    <Dialog
      open={logoutDialog}
      onClose={handleLogoutClose}
      sx={{
        "& .MuiPaper-root": { borderRadius: "0.83vw", maxWidth: "61.80vw" },
      }}
      BackdropProps={{
        invisible: true,
      }}
    >
      <DialogContent
        className={classes.logoutAlertDialogContainer}
        onClick={handleLogout}
      >
        <div className={classes.sidebar_menu}>
          <img src={Logout} alt="Trash" className={classes.arrow_icon}/>
          <p className={classes.sidebar_menu_text}>Log Out</p>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LogoutAlert;
