import React, { useRef } from "react";
import classes from "./Utils.module.scss";


interface FileInputProps {
  onFileSelect: (files: FileList | null) => void;
}
const VideoFileUpload : React.FC<FileInputProps> =({ onFileSelect })=> {

  
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file=e.target.files;
    if (file) {
      onFileSelect(file);
    }
  };
  
  const videoRef = useRef<HTMLInputElement>(null);
  

  const handleUploadClick = (): void => {
    // Upload selected file to server
      videoRef!.current!.click();
  };

  return (
    <div className={classes.pushStarters_UploadVideoButton}>
      <p onClick={handleUploadClick}>
        Browse Files
        <input
          hidden
          type="file"
          ref={videoRef}
          multiple
          onChange={handleFileChange}
          accept=".mp4,.mpe4,.mkv,.webm,.mov"
        />
      </p>
    </div>
  );
}

export default VideoFileUpload;
