import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../../../../app/index";
import { RootState } from "../../../../../app/store";

export interface EditStoryState {
  editStory: any;
  status: "idle" | "loading" | "failed";
}

const initialState: EditStoryState = {
  editStory: null,
  status: "idle",
};

export const addToStory = createAsyncThunk("widget/existstory", async () => {
  const url = `${process.env.REACT_APP_BASE_URL}/widget/story`;
  
  const response = await API.get(url);
  // The value we return becomes the `fulfilled` action payload
  return response.data;
});

export const editStorySlice = createSlice({
  name: "editStory",
  initialState,
  reducers: {
    addToStory: (state: EditStoryState, action: PayloadAction) => {
      state.editStory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToStory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addToStory.fulfilled, (state, action) => {
        state.status = "idle";
        state.editStory = action.payload;
      })
      .addCase(addToStory.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectEditStoryState = (state: RootState) => state.editStore;
export default editStorySlice.reducer;
