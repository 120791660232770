import React, { useState, useEffect, useCallback, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";

import { selectDashboardHomeState } from "../../templates/DashboardHome/DashboardHomeSlice";

import {
  getQueryProducts,
  selectStoriesPageSliceState,
  getProducts,
} from "./Slices/StoriesPageSlice/StoriesPageSlice";
import classes from "./StoriesPage.module.scss";
import SearchIcon from "../../../assets/searchIcon.svg";
import VideoFileUpload from "../../../utils/VideoFileUpload";
import ProductTable from "../../Common/Table/ProductTable";
import { Products as ProductColumn } from "../../StaticData/index";
import ProductTableRow from "../../Common/Table/ProductTableRow";
import { createStory } from "./Slices/ProductPageSlice/ProductPageSlice";
import {
  selectAddVideoPageState,
  getAllMedia,
} from "./Slices/AddVideoPageSlice/AddVideoPageSlice";
import { getVideoData } from "../../../utils/getVideoData";
import { makeVideoDispatch } from "../../../utils/makeVideoDispatch";
import {
  addToStory,
  selectEditStoryState,
} from "./Slices/EditStory/EditStorySlice";
import LiveStoryDialog from "../../Common/LiveStoryDialog";
import { getMediaList } from "../../../utils/getMediaOrder";
import {
  liveStory,
  draftStory,
} from "./Slices/EditVideoScreen/EditVideoScreenSlice";
import Check from "../../../assets/check.svg";
import Cross from "../../../assets/cross.svg";
import { Box, CircularProgress } from "@mui/material";
import EditStoryTable from "../../Common/Table/EditStoryTable";
import DraftAlert from "../../Common/Table/DraftAlert";
import ExistStoryAlert from "../../Common/ExistStoryAlert";
import { selectProductPageState } from "./Slices/ProductPageSlice/ProductPageSlice";
import { debounce } from "lodash";
import circle from "../../../assets/x-circle.svg";

function StoriesPage({
  handleClose,
  page,
  setPage,
  setSelectedStory,
  selectedStory,
  selectedProductId,
  setSelectedProductId,
  setIsCreateStory,
  isCreateStory,
}: {
  handleClose: any;
  page: string;
  setPage: any;
  setSelectedStory: React.Dispatch<React.SetStateAction<string | null>>;
  selectedStory: string | null;
  selectedProductId: number;
  setSelectedProductId: React.Dispatch<React.SetStateAction<number>>;
  setIsCreateStory: React.Dispatch<React.SetStateAction<boolean>>;
  isCreateStory: boolean;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const userStoreData = useAppSelector(selectDashboardHomeState);
  const productData = useAppSelector(selectStoriesPageSliceState);
  const editStoryData = useAppSelector(selectEditStoryState);
  const newStoryData = useAppSelector(selectProductPageState);
  const [input, setInput] = useState({
    name: "",
    description: "",
  });
  const [paginatePage, setPaginatePage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const temp = JSON.parse(localStorage.getItem("storeInfo") || "{}");

  //fetching all the products from the store
  const getAllProducts = () => {
    dispatch(
      getProducts({
        store_id: userStoreData?.store?.data[0]?._id || temp?.data[0]?._id,
        setLoading,
        setTotal,
      })
    );
  };

  const [editStory, setEditStory] = useState<boolean>(false);
  const [existStoryPopUp, setExistStoryPopUp] = useState<boolean>(false);

  const editExistStory = (e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation();
    dispatch(addToStory());
  };

  //creating the new story as draft
  const handleCreateStory = () => {
    dispatch(
      createStory({
        name: input.name,
        description: input.description,
        store: userStoreData?.store?.data[0]?._id || temp?.data[0]?._id,
        product: selectedProductId,
      })
    );
  };

  const [disableButton, setDisableButton] = useState(true);
  const handleCheckboxChange = (productId: number) => {
    setSelectedProductId(productId);
  };

  useEffect(() => {
    productData &&
      productData?.products?.data.map((item: any, index: number) => {
        if (
          item &&
          item?._id === selectedProductId &&
          item?.story?.status === "Live"
        ) {
          setExistStoryPopUp(true);
          setDisableButton(true);
        } else if (
          item &&
          item?._id === selectedProductId &&
          item?.story?.status !== "Live"
        ) {
          setDisableButton(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductId, newStoryData]);

  interface UnboxingObject {
    file: FileList;
    category: string;
  }

  interface SpecObject {
    file: FileList;
    category: string;
  }

  interface ReviewObject {
    file: FileList;
    category: string;
  }
  interface Unboxing {
    status: string;
    uploadedFile: File;
  }

  interface Spec {
    status: string;
    uploadedFile: File;
  }

  interface Review {
    status: string;
    uploadedFile: File;
  }
  interface progressBarProps {
    mediaId: string;
    progress: number;
    type: string;
  }

  const [unBoxingvideo, setUnboxingVideo] = useState<UnboxingObject[]>([]);
  const [unboxingFile, setUnboxingFile] = useState<Unboxing[]>([]);
  const [specFile, setSpecFile] = useState<Spec[]>([]);
  const [reviewFile, setReviewFile] = useState<Review[]>([]);
  const [specVideo, setSpecVideo] = useState<SpecObject[]>([]);
  const [reviewVideo, setReviewVideo] = useState<ReviewObject[]>([]);

  const [progress, setProgress] = useState<progressBarProps[]>([]);

  const handleStoryId = (_id: string) => {
    setSelectedStory(_id);
  };

  useEffect(() => {
    // This code will execute after the unBoxingvideo state value has changed
    const handleDispatch = async () => {
      const videoArray = await getVideoData(unBoxingvideo);

      makeVideoDispatch(
        dispatch,
        videoArray,
        selectedProductId,
        unBoxingvideo,
        setUnboxingFile,
        setProgress,
        selectedStory,
        progress
      );
    };
    handleDispatch();

    if (unBoxingvideo.length > 0) {
      setUnboxingVideo([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedProductId, selectedStory, unBoxingvideo]);

  useEffect(() => {
    // This code will execute after the unBoxingvideo state value has changed
    const handleDispatch = async () => {
      const videoArray = await getVideoData(specVideo);
      makeVideoDispatch(
        dispatch,
        videoArray,
        selectedProductId,
        specVideo,
        setSpecFile,
        setProgress,
        selectedStory,
        progress
      );
    };
    handleDispatch();

    if (specVideo.length > 0) {
      setSpecVideo([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedProductId, selectedStory, specVideo]);

  useEffect(() => {
    // This code will execute after the unBoxingvideo state value has changed
    const handleDispatch = async () => {
      const videoArray = await getVideoData(reviewVideo);
      await makeVideoDispatch(
        dispatch,
        videoArray,
        selectedProductId,
        reviewVideo,
        setReviewFile,
        setProgress,
        selectedStory,
        progress
      );
    };
    handleDispatch();

    if (reviewVideo.length > 0) {
      setReviewVideo([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reviewVideo, selectedProductId, selectedStory]);

  const handleUnboxingFileSelect = async (
    file: FileList | null,
    category: string
  ) => {
    if (file) {
      const newFileObject = { file, category, progress: false };
      setUnboxingVideo((prev) => [...prev, { ...newFileObject }]);
    }
  };

  const handleSpecsFileSelect = async (
    file: FileList | null,
    category: string
  ) => {
    if (file) {
      const newFileObject = { file, category };
      setSpecVideo((prev) => [...prev, { ...newFileObject }]);
    }
  };

  const handleReviewFileSelect = async (
    file: FileList | null,
    category: string
  ) => {
    if (file) {
      const newFileObject = { file, category };
      setReviewVideo((prev) => [...prev, { ...newFileObject }]);
    }
  };

  const getAllMediaList = () => {
    dispatch(getAllMedia({ _id: selectedStory }));
    setSpecFile([]);
    setUnboxingFile([]);
    setReviewFile([]);
  };
  const videoData = useAppSelector(selectAddVideoPageState);
  const [liveDialog, setLiveDialog] = useState(false);
  const [draftDialog, setDraftDialog] = useState(false);
  const handleLiveDialogOpen = () => {
    setLiveDialog(true);
  };

  const handleLiveDialogClose = () => {
    setLiveDialog(false);
  };

  const handleDraftDialogOpen = () => {
    setDraftDialog(true);
  };

  const handleDraftDialogClose = () => {
    setDraftDialog(false);
  };

  type DataItem = {
    gif: any;
    deleted: any;
    thumbnailUrl: string;
    thumbnailUploaded: boolean;
    thumbnailReplaced: boolean;
    thumbnailVideoUpload: boolean;
    thumbnailVideoReplace: boolean;
    _id: string;
    name: string;
    title: string;
    use_case: string;
  };

  type deleteObj = {
    _id: string;
    delete: boolean;
  };
  const [videos, setVideos] = useState<DataItem[]>([]);
  const [disableRow, setDisableRow] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<deleteObj[]>([]);
  useEffect(() => {
    if (videoData?.mediaList?.data[0].media) {
      setVideos(videoData?.mediaList?.data[0].media);
    }
  }, [videoData?.mediaList]);

  useEffect(() => {
    setVideos((prevState) =>
      prevState.map((obj) => ({
        ...obj,
        newTitle: obj.title,
        newUse_Case: obj.use_case,
        thumbnailUploaded: false,
        thumbnailReplaced: false,
        thumbnailVideoUpload: false,
        thumbnailVideoReplace: false,
        enabled: true,
        deleted: false,
      }))
    );
  }, [videoData?.mediaList]);

  const { updateMediaData, storyObj, updatedRowObj, draftStoryObj } =
    getMediaList(
      videoData?.mediaList?.data[0],
      videos,
      selectedProductId,
      deleteId
    );

  const handleLiveStatus = () => {
    dispatch(
      liveStory({
        media: updateMediaData,
        story: [storyObj],
        product: [updatedRowObj],
      })
    );
    setLiveDialog(false);
    setDisableRow(false);
    setDeleteId([]);
    handleClose();
    setIsCreateStory(false);
  };

  const handleDraftStatus = () => {
    dispatch(
      draftStory({
        media: updateMediaData,
        story: [draftStoryObj],
        product: [updatedRowObj],
      })
    );
    setLiveDialog(false);
    handleClose();
    setIsCreateStory(false);
  };

  const handleExistStoryPage = () => {
    if (editStory) {
      handleClose();
      setEditStory(false);
    } else {
      setPage("Product_page");
    }
  };

  const handleDashboardEdit = () => {
    handleClose();
  };

  useEffect(() => {
    editStoryData?.editStory?.data?.map((item: any, index: number) => {
      if (item._id === selectedStory) {
        setSelectedProductId(item?.product?._id);
      }
    });
  }, [editStoryData?.editStory?.data, selectedStory, setSelectedProductId]);

  useEffect(() => {
    if (newStoryData) {
      setSelectedStory(newStoryData?._id);
    }
  }, [newStoryData, setSelectedStory]);
  const [disableFinish, setDisableFinish] = useState<boolean>(true);

  useEffect(() => {
    videos &&
      videos.forEach((video, index) => {
        if (video?.thumbnailUploaded === false && video?.thumbnailUrl) {
          let videoObj = { ...video, thumbnailUploaded: true };
          let list = [...videos];
          list[index] = videoObj;
          setVideos(list);
        }
      });
      videos &&
      videos.forEach((video, index) => {
        if (video?.thumbnailVideoUpload === false && video?.gif.downloadUrl) {
          let videoObj = { ...video, thumbnailVideoUpload: true };
          let list = [...videos];
          list[index] = videoObj;
          setVideos(list);
        }
      });
    const allTrue = videos.every((obj, index) => {
      if (obj?.deleted) {
        return true;
      } else {
        return obj?.thumbnailUploaded === true
      }
    });
    if (allTrue) {
      setDisableFinish(false);
    } else {
      setDisableFinish(true);
    }
  }, [videoData?.mediaList, videos]);

  const handleDeleteClose = () => {
    setExistStoryPopUp(false);
  };
  const [searchInput, setSearchInput] = useState<string>("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (productData?.products) {
      setProducts(productData?.products?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData.products]);

  const dispatchSearch = (search: string) => {
    if (search !== "") {
      if (search.length > 2) {
        dispatch(
          getQueryProducts({
            store_id: userStoreData?.store?.data[0]?._id || temp?.data[0]?._id,
            title: search,
            setLoading,
          })
        );
      }
    } else {
      dispatch(
        getProducts({
          store_id: userStoreData?.store?.data[0]?._id || temp?.data[0]?._id,
          setLoading,
          setTotal,
        })
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleQueryChangeDebounced = useCallback(
    debounce(dispatchSearch, 500),
    []
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearchInput(search);
    handleQueryChangeDebounced(search);
  };

  useEffect(() => {
    if (searchInput === "") {
      handleQueryChangeDebounced(searchInput);
    }
  }, [handleQueryChangeDebounced, searchInput]);

  useEffect(() => {
    dispatch(
      getProducts({
        store_id: userStoreData?.store?.data[0]?._id || temp?.data[0]?._id,
        setLoading,
        skip: paginatePage * 20,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, paginatePage]);

  return (
    <>
      {page === "create_Story" ? (
        <>
          <h2 className={classes.pushStarters_CreateStoryHeadingText}>
            Create New Story
          </h2>
          <div className={classes.pushStarters_StoryNameContainer}>
            <h4 className={classes.pushStarters_StoryNameLabel}>Name</h4>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter item name"
              value={input.name}
              onChange={(e) => setInput({ ...input, name: e.target.value })}
            />
          </div>
          <div className={classes.pushStarters_StoryDescContainer}>
            <h4 className={classes.pushStarters_StoryDescLabel}>Description</h4>
            <textarea
              id="name"
              name="name"
              rows={3}
              placeholder="Enter item description"
              value={input.description}
              onChange={(e) =>
                setInput({ ...input, description: e.target.value })
              }
            />
          </div>
          <p
            className={classes.pushStarters_EditStoryButton}
            onClick={(e) => {
              setEditStory(true);
              editExistStory(e);
            }}
          >
            Add to existing story
          </p>
          <div className={classes.storyDropdown}>
            {editStory && (
              <>
                <label htmlFor="story" className={classes.storylabel}>
                  Select Story
                </label>
                <select
                  onChange={(e) => {
                    handleStoryId(e.target.value);
                    setPage("Add_videos");
                  }}
                >
                  <option value="Select">Select</option>
                  {editStoryData?.editStory?.data?.map((option: any) => {
                    return (
                      <option key={option?._id} value={option?._id}>
                        {option?.product?.title} - {option?.name} -{" "}
                        {option?.status}
                      </option>
                    );
                  })}
                </select>
              </>
            )}
          </div>

          <input
            type="submit"
            value="Cancel"
            className={classes.pushStarters_CancelStoryButton}
            onClick={handleClose}
          />
          <input
            type="submit"
            value="Create"
            disabled={input.name ? false : true}
            className={classes.pushStarters_CreateStoryButton}
            onClick={() => {
              setPage("Product_page");
              getAllProducts();
              setIsCreateStory(true);
            }}
          />
        </>
      ) : page === "Product_page" ? (
        <div>
          <h1 className={classes.pushStarters_ProductpageHeadingText}>
            Select Product & Product URLs
          </h1>
          <p className={classes.pushStarters_ProductpageSubHeadingText}>
            Select to which product you want to add stories
          </p>
          <div className={classes.searchTextBoxContainer}>
            <img
              src={SearchIcon}
              alt="search"
              className={classes.pushStarters_SearchIcon}
            />
            <input
              type="text"
              placeholder="Enter Product Name or Product ID"
              value={searchInput}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
            {searchInput !== "" && (
              <img
                src={circle}
                alt="clear"
                className={classes.pushStarters_ClearIcon}
                onClick={() => setSearchInput("")}
              />
            )}
          </div>
          <div className={classes.pushStarters_TableContainer}>
            <ProductTable
              paginate={true}
              total={total}
              setPaginatePage={setPaginatePage}
              paginatePage={paginatePage}
            >
              {loading ? (
                <CircularProgress
                  size={23}
                  className={classes.pushStarters_CircularProgress}
                />
              ) : products?.length > 0 ? (
                products?.map((item: any, index: number) => {
                  return (
                    <ProductTableRow
                      key={index}
                      data={item}
                      columns={ProductColumn.config.columns}
                      selectedProductId={selectedProductId}
                      handleCheckboxChange={(data: number) => {
                        handleCheckboxChange(data);
                      }}
                    />
                  );
                })
              ) : (
                <div className={classes.pushStarters_NoProductText}>
                  No Product Found
                </div>
              )}
            </ProductTable>
          </div>
          <input
            type="submit"
            value="Back"
            className={classes.pushStarters_ProductBackButton}
            onClick={() => {
              setPage("create_Story");
            }}
          />
          <input
            type="submit"
            value="Next"
            className={classes.pushStarters_ProductNextButton}
            disabled={disableButton}
            onClick={() => {
              setPage("Add_videos");
              handleCreateStory();
            }}
          />
        </div>
      ) : page === "Add_videos" ? (
        <div>
          <div>
            <h1 className={classes.pushStarters_AddVideoHeadingText}>
              Add Videos
            </h1>
            <p className={classes.pushStarters_AddVideoSubHeadingText}>
              Upload videos in MP4, WEBM formats. Max 150MB each
            </p>
          </div>
          <div>
            <div className={classes.pushStarters_UnboxingVideoContainer}>
              <div style={{ height: "max-content" }}>
                <h1 className={classes.pushStarters_UnboxingVideoHeadingText}>
                  Unboxing
                </h1>
                <p className={classes.pushStarters_UnboxingVideoDetails}>
                  Unboxing videos are those type of videos that help the
                  consumer understand the look and feel of product and also what
                  all things are provided in the product package
                </p>

                <VideoFileUpload
                  onFileSelect={(files) =>
                    handleUnboxingFileSelect(files, "Unboxing")
                  }
                />

                <p
                  className={classes.pushStarters_UnboxingUploadedVideoHeading}
                >
                  Uploaded Files
                </p>
                <div className={classes.pushStarters_UnboxingUploadedVideoName}>
                  {unboxingFile?.map((item, index) => {
                    return (
                      <>
                        <div>
                          <p>{item?.uploadedFile?.name}</p>
                          <p
                            className={
                              classes.pushStarters_UnboxingUploadedVideoSize
                            }
                          >
                            {(item?.uploadedFile?.size / (1024 * 1024)).toFixed(
                              1
                            )}{" "}
                            Mb
                          </p>
                        </div>
                        {item.status !== "complete" ? (
                          <Box
                            sx={{
                              marginTop: "1rem",
                            }}
                            className={classes.uploadProgress}
                          >
                            {progress[index] &&
                            progress[index].progress < 97 &&
                            item?.uploadedFile?.size < 150 * 1024 * 1024 ? (
                              <CircularProgress
                                variant="determinate"
                                value={progress[index].progress}
                                size={23}
                              />
                            ) : item?.uploadedFile?.size < 150 * 1024 * 1024 ? (
                              <img
                                key={index}
                                src={Check}
                                alt="complete"
                                style={{ width: "24px", height: "24px" }}
                              />
                            ) : (
                              <img
                                key={index}
                                src={Cross}
                                alt="X"
                                style={{ width: "24px", height: "24px" }}
                              />
                            )}
                          </Box>
                        ) : (
                          <img
                            key={index}
                            src={Check}
                            alt="complete"
                            className={classes.checkVideoUpload}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={classes.pushStarters_SpecsVideoContainer}>
              <div style={{ height: "max-content" }}>
                <h1 className={classes.pushStarters_SpecsVideoHeadingText}>
                  Specs
                </h1>
                <p className={classes.pushStarters_SpecsVideoSubHeadingText}>
                  Specs videos are those type of videos that help explain the
                  product's specifications, features, or contents
                </p>
                <VideoFileUpload
                  onFileSelect={(files) =>
                    handleSpecsFileSelect(files, "Specs")
                  }
                />
                <p className={classes.pushStarters_SpecsUploadedVideoHeading}>
                  Uploaded Files
                </p>
                <div className={classes.pushStarters_SpecsUploadedVideoName}>
                  {specFile?.map((item, index) => {
                    return (
                      <>
                        <div>
                          <p>{item?.uploadedFile?.name}</p>
                          <p
                            className={
                              classes.pushStarters_UnboxingUploadedVideoSize
                            }
                          >
                            {(item?.uploadedFile?.size / (1024 * 1024)).toFixed(
                              1
                            )}{" "}
                            Mb
                          </p>
                        </div>

                        {item.status !== "complete" ? (
                          <Box
                            sx={{
                              marginTop: "1rem",
                            }}
                            className={classes.uploadProgress}
                          >
                            {progress[index + unboxingFile.length] &&
                            progress[index + unboxingFile.length]?.progress <
                              97 &&
                            item?.uploadedFile?.size < 150 * 1024 * 1024 ? (
                              <CircularProgress
                                variant="determinate"
                                value={
                                  progress[index + unboxingFile.length]
                                    ?.progress
                                }
                                size={23}
                              />
                            ) : item?.uploadedFile?.size < 150 * 1024 * 1024 ? (
                              <img
                                key={index}
                                src={Check}
                                alt="complete"
                                style={{ width: "24px", height: "24px" }}
                              />
                            ) : (
                              <img
                                key={index}
                                src={Cross}
                                alt="X"
                                style={{ width: "24px", height: "24px" }}
                              />
                            )}
                          </Box>
                        ) : (
                          <img
                            key={index}
                            src={Check}
                            alt="complete"
                            className={classes.checkVideoUpload}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={classes.pushStarters_ReviewVideoContainer}>
              <div style={{ height: "max-content" }}>
                <h1 className={classes.pushStarters_ReviewVideoHeadingText}>
                  Reviews
                </h1>
                <p className={classes.pushStarters_ReviewVideoSubHeadingText}>
                  Review videos are those videos in which an expert, youtuber or
                  the customer is giving their eviews related to the product
                </p>
                <VideoFileUpload
                  onFileSelect={(files) =>
                    handleReviewFileSelect(files, "Expert Reviews")
                  }
                />
                <p className={classes.pushStarters_ReviewUploadedVideoText}>
                  Uploaded Files
                </p>
                <div className={classes.pushStarters_ReviewUploadedVideoName}>
                  {reviewFile?.map((item, index) => {
                    return (
                      <>
                        <div>
                          <p>{item?.uploadedFile?.name}</p>
                          <p
                            className={
                              classes.pushStarters_UnboxingUploadedVideoSize
                            }
                          >
                            {(item?.uploadedFile?.size / (1024 * 1024)).toFixed(
                              1
                            )}{" "}
                            Mb
                          </p>
                        </div>

                        {item.status !== "complete" ? (
                          <Box
                            sx={{
                              marginTop: "1rem",
                            }}
                            className={classes.uploadProgress}
                          >
                            {progress[
                              index + unboxingFile.length + specFile.length
                            ] &&
                            progress[
                              index + unboxingFile.length + specFile.length
                            ]?.progress < 97 &&
                            item?.uploadedFile?.size < 150 * 1024 * 1024 ? (
                              <CircularProgress
                                variant="determinate"
                                value={
                                  progress[
                                    index +
                                      unboxingFile.length +
                                      specFile.length
                                  ]?.progress
                                }
                                size={23}
                              />
                            ) : item?.uploadedFile?.size < 150 * 1024 * 1024 ? (
                              <img
                                key={index}
                                src={Check}
                                alt="complete"
                                style={{ width: "24px", height: "24px" }}
                              />
                            ) : (
                              <img
                                key={index}
                                src={Cross}
                                alt="X"
                                style={{ width: "24px", height: "24px" }}
                              />
                            )}
                          </Box>
                        ) : (
                          <img
                            key={index}
                            src={Check}
                            alt="complete"
                            className={classes.checkVideoUpload}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <input
            type="submit"
            value="Back"
            className={
              isCreateStory
                ? classes.pushStarters_Create_Story_VideoPageBack
                : classes.pushStarters_VideoPageBackButton
            }
            onClick={() => {
              handleExistStoryPage();
              handleDashboardEdit();
            }}
          />
          <input
            type="submit"
            value="Next"
            disabled={
              unboxingFile?.length === 0 &&
              specFile?.length === 0 &&
              reviewFile?.length === 0
            }
            className={
              isCreateStory
                ? classes.pushStarters_Create_Story_VideoPageNext
                : classes.pushStarters_VideoPageNextButton
            }
            onClick={() => {
              setPage("Edit_stories");
              getAllMediaList();
            }}
          />
          {!isCreateStory && (
            <input
              type="submit"
              value="Skip"
              className={classes.pushStarters_VideoPageSkipButton}
              onClick={() => {
                setPage("Edit_stories");
                getAllMediaList();
              }}
            />
          )}

          <div style={{ height: "24px" }}></div>
        </div>
      ) : (
        page === "Edit_stories" && (
          <div>
            <h1 className={classes.pushStarters_EditStoriesHeadingText}>
              Edit
            </h1>
            <p className={classes.pushStarters_EditStoriesSubHeadingText}>
              Add title to videos, choose video categories, Activate/deactivate
              videos, delete videos here.
            </p>
            <div className={classes.pushStarters_EditVideoTableContainer}>
              <EditStoryTable
                videos={videos}
                setVideos={setVideos}
                getAllMediaList={getAllMediaList}
                setDisableRow={setDisableRow}
                disableRow={disableRow}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
              />
            </div>
            <div>
              <input
                type="submit"
                value="Back"
                className={classes.pushStarters_EditPageBackButton}
                onClick={() => {
                  setPage("Add_videos");
                }}
              />
              <input
                type="submit"
                value="Save Draft"
                className={classes.pushStarters_EditPageDraftButton}
                onClick={() => handleDraftDialogOpen()}
              />
              <input
                type="submit"
                value="Finish"
                disabled={disableFinish}
                className={classes.pushStarters_EditPageFinishButton}
                onClick={() => handleLiveDialogOpen()}
              />
            </div>
          </div>
        )
      )}
      {liveDialog && (
        <LiveStoryDialog
          liveDialog={liveDialog}
          handleLiveDialogClose={handleLiveDialogClose}
          handleLiveStatus={handleLiveStatus}
        />
      )}
      {draftDialog && (
        <DraftAlert
          draftDialog={draftDialog}
          handleDraftDialogClose={handleDraftDialogClose}
          handleDraftStatus={handleDraftStatus}
        />
      )}
      {existStoryPopUp && (
        <ExistStoryAlert
          existStory={existStoryPopUp}
          handleDeleteClose={handleDeleteClose}
        />
      )}
    </>
  );
}

export default StoriesPage;
