import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import classes from "./scss/Common.module.scss";
import Confirm from "../../assets/confirm.svg";
interface PopupDialogBoxProps {
  liveDialog: boolean;
  handleLiveDialogClose: () => void;
  handleLiveStatus:()=>void;
}

const LiveStoryDialog: React.FC<PopupDialogBoxProps> = ({
  liveDialog,
  handleLiveDialogClose,
  handleLiveStatus,
}) => {
  return (
    <Dialog
      open={liveDialog}
      onClose={handleLiveDialogClose}
      sx={{
        "& .MuiPaper-root": { borderRadius: "0.83vw" },
      }}
    >
      <DialogContent className={classes.liveAlertContainer}>
        <img src={Confirm} alt="Confirm" className={classes.liveConfirmImg}/>
        <h1 className={classes.liveAlertHeadingText}>Going Live</h1>
        <p className={classes.liveAlertSubText}>You are about to make this story live on your product page</p>
        <input
          type="submit"
          value="Cancel"
          className={classes.liveCancelButton}
          onClick={handleLiveDialogClose}
        />
        <input
          type="submit"
          value="Confirm"
          className={classes.liveConfirmButton}
          onClick={handleLiveStatus}
        />
      </DialogContent>
    </Dialog>
  );
};

export default LiveStoryDialog;
