import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import Router from "./routes/router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App(): JSX.Element {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY!,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ["production", "staging"],
  });

  const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

  return (
    <ErrorBoundary>
      <ToastContainer
        autoClose={3000}
        limit={3}
        pauseOnFocusLoss={true}
        pauseOnHover={true}
        theme="colored"
      />
      <Router />
    </ErrorBoundary>
  );
}

export default App;
