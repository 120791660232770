import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../../../app/index"
import { RootState } from "../../../../app/store";

export interface LoginScreenOtpState {
  user: any;
  status: "idle" | "loading" | "failed";
}

const initialState: LoginScreenOtpState = {
  user: null,
  status: "idle",
};

export const createStore = createAsyncThunk(
  "widget/store",
  async ({
    name,
    vendor,
    account_id,
    domain,
    session,
    ga_stream_id,
    navigate
  }: {
    name: string;
    vendor: string;
    account_id: string;
    domain: string;
    session: {
      session_id: string;
      api_key: string;
    };
    ga_stream_id: string;
    navigate:any
  }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/widget/store`;
    const response = await API.post(url, {
      name,
      vendor,
      account_id,
      domain,
      session,
      ga_stream_id,
    });
    if(response.status===200){
      navigate("/dashboard/home")
    }
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const loginConnectWebsiteSlice = createSlice({
  name: "loginScreenOtp",
  initialState,
  reducers: {
    login: (state: LoginScreenOtpState, action: PayloadAction) => {
      //state.user = action.payload;
    },
    register: (state: LoginScreenOtpState, action: PayloadAction) => {
      //state.user= action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStore.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createStore.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
      })
      .addCase(createStore.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectLoginConnectWebsiteState = (state: RootState) =>
  state.linkStore;
export default loginConnectWebsiteSlice.reducer;
