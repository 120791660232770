import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../../../../app/index"
import { RootState } from "../../../../../app/store";
import {getDashboard} from "../../../../templates/DashboardHome/DashboardHomeSlice"
import { showToast } from "../../../../../utils/ErrorMessage";
export interface EditVideoScreenState {
  deleteState: any;
  liveStoryState:any;
  draftStory:any;
  status: "idle" | "loading" | "failed";

}

const initialState: EditVideoScreenState = {
  deleteState: null,
  liveStoryState:null,
  draftStory:null,
  status: "idle",
};

export const deleteVideo = createAsyncThunk(
  "widget/media/delete",
  async ({ media }: { media: any }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/widget/media/bulk-update`;
    const response = await API.put(url, { media });
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const liveStory = createAsyncThunk(
  "widget/media/live",
  async ({ media,story,product }: { media: any,story:any,product:any },{dispatch}) => {
    const url = `${process.env.REACT_APP_BASE_URL}/widget/media/bulk-update`;
    try{
      const response = await API.put(url, { media,story,product });
    // The value we return becomes the `fulfilled` action payload
    
      showToast("success", "Story Made Live Successfully");
      dispatch(getDashboard({}))
    return response.data;
    }catch(err:any){
      console.log(err);
      showToast("error", err.response.data.message);
    }
  }
);

export const draftStory = createAsyncThunk(
  "widget/media/draft",
  async ({ media,story,product }: { media: any,story:any,product:any },{dispatch}) => {
    const url = `${process.env.REACT_APP_BASE_URL}/widget/media/bulk-update`;
    try{
      const response = await API.put(url, { media,story,product });
        showToast("success", "Story added to Draft Successfully");

        dispatch(getDashboard({}))
     
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    }catch(err:any){
      console.log(err);
      showToast("error", err.response.data.message);
    }
  }
);

export const editVideoScreenSlice = createSlice({
  name: "editVideoScreen",
  initialState,
  reducers: {
    deleteVideo: (state: EditVideoScreenState, action: PayloadAction) => {
      state.deleteState = action.payload;
    },
    liveStory: (state: EditVideoScreenState, action: PayloadAction) => {
      state.liveStoryState = action.payload;
    },
    draftStory: (state: EditVideoScreenState, action: PayloadAction) => {
      state.draftStory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteVideo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        state.status = "idle";
        state.deleteState = action.payload;
      })
      .addCase(deleteVideo.rejected, (state) => {
        state.status = "failed";
      });
      builder
      .addCase(liveStory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(liveStory.fulfilled, (state, action) => {
        state.status = "idle";
        state.liveStoryState = action.payload;
      })
      .addCase(liveStory.rejected, (state) => {
        state.status = "failed";
      });
      builder
      .addCase(draftStory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(draftStory.fulfilled, (state, action) => {
        state.status = "idle";
        state.draftStory = action.payload;
      })
      .addCase(draftStory.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectEditVideoScreenState = (state: RootState) => state.videoActionStore;
export default editVideoScreenSlice.reducer;
