import React, { ReactNode } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from "@mui/material";
import classes from "../scss/Common.module.scss";

interface ProductTableProps {
  children: ReactNode;
  paginate: any;
  total: any;
  setPaginatePage: any;
  paginatePage: any;
}

function ProductTable({
  children,
  paginate,
  total,
  setPaginatePage,
  paginatePage,
}: ProductTableProps): JSX.Element {
  const onPageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setPaginatePage && setPaginatePage(page);
  };
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ overflow: "auto", maxHeight: "47vh" }}
      >
        <Table>
          <TableHead>
            <TableRow className={classes.productTableHeaderStyle}>
              <td className={classes.tableHeadStyle} />
              <TableCell className={classes.tableHeadStyle}>
                Product Name
              </TableCell>
              <TableCell className={classes.tableHeadStyle}>Product</TableCell>
              <TableCell className={classes.tableHeadStyle}>
                Product ID
              </TableCell>
              <TableCell className={classes.tableHeadStyle}>
                Product URL
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      {paginate && (
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={total!}
          rowsPerPage={20}
          page={paginatePage!}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}

export default ProductTable;
