import React, { useState } from "react";
import SidebarList from "./SideBarList";
import { sidebarItemsData } from "../../StaticData";
import troopod from "../../../assets/troopodIcon.png";
import classes from "./Dashboard.module.scss";
import Profile from "../../../assets/Profile.jpg";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { logoutUser } from "../../Auth/authSlice";
import { useAppDispatch } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import LogoutAlert from "../../Common/LogoutAlert";

interface SidebarItem {
  icon: React.FunctionComponent<SvgIconProps>;
  tab_name: string;
  permalink: string;
}

function SideBar() {

  const dispatch = useAppDispatch();
  const navigate=useNavigate();
  const [logoutDialog,setLogoutDialog]=useState(false);

  const handleLogoutOpen =()=>{
    setLogoutDialog(true);
  }

  const handleLogoutClose =()=>{
    setLogoutDialog(false);
  }

  const handler = (sidebarItemsData?: SidebarItem[]) => {
    sidebarItemsData = sidebarItemsData || [];
    return (
      <div className={classes.collapsed_sidebar_menu}>
        {sidebarItemsData.length > 0 &&
          sidebarItemsData.map((subOption, i) => {
            return (
              <SidebarList
                key={i}
                data={subOption}
              />
            );
          })}
      </div>
    );
  };

  const sidebarList = handler(sidebarItemsData) || [];

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/signin")
  };

  return (
    <>
      <img src={troopod} alt="troopod icon" className={classes.troopodIcon} />
      {sidebarList}
      <div className={classes.profileIcon}>
        <img src={Profile} alt="profile" style={{cursor:"pointer"}} onClick={handleLogoutOpen} />
      </div>
      {<LogoutAlert handleLogoutClose={handleLogoutClose} logoutDialog={logoutDialog} handleLogout={handleLogout}/>}
    </>
  );
}

export default React.memo(SideBar);
