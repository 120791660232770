import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import classes from "../scss/Common.module.scss";
import Draft from "../../../assets/Draft.svg";
interface PopupDialogBoxProps {
  draftDialog: boolean;
  handleDraftDialogClose: () => void;
  handleDraftStatus:()=>void;
}

const DraftStoryDialog: React.FC<PopupDialogBoxProps> = ({
  draftDialog,
  handleDraftDialogClose,
  handleDraftStatus,
}) => {
  return (
    <Dialog
      open={draftDialog}
      onClose={handleDraftDialogClose}
      sx={{
        "& .MuiPaper-root": { borderRadius: "0.83vw" },
      }}
    >
      <DialogContent className={classes.liveAlertContainer}>
        <img src={Draft} alt="Draft" className={classes.draftAlert}/>
        <h1 className={classes.draftAlertHeading}>Saving as Draft</h1>
        <p className={classes.draftAlertSubHeading}>Do you want to ave this as Draft and make it live after?</p>
        <input
          type="submit"
          value="Cancel"
          className={classes.liveCancelButton}
          onClick={handleDraftDialogClose}
        />
        <input
          type="submit"
          value="Confirm"
          className={classes.liveConfirmButton}
          onClick={handleDraftStatus}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DraftStoryDialog;
