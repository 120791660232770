export const getVideoData = async (categoryState: any) => {
  let videosObj: {
    ContentType: any;
    title: any;
    extension?: any;
    thumbnailContentType?: string | undefined;
    thumbnailExtension?: string | undefined;
    thumbnailAvailable?: boolean;
    use_case?: any;
  };
  let uploadVideos: {
    ContentType: any;
    extension?: any;
    thumbnailContentType?: string | undefined;
    thumbnailExtension?: string | undefined;
    thumbnailAvailable?: boolean | undefined;
    title: any;
    use_case?: any;
  }[] = [];

  categoryState.map(
    (
      item: { file: { [x: string]: { [x: string]: any } }; category: any },
      index: any
    ) => {
      for (let key in item?.file) {
        if (item?.file[key]["size"] > 150 * 1024 * 1024) {
          alert(
            "Error!!!Wrong video format or file size exceeded maximum Limit"
          );
        } else {
          videosObj = {
            ContentType: item?.file[key]["type"],
            extension: item?.file[key]["type"]?.split("/")[1],
            thumbnailContentType: "",
            thumbnailExtension: "",
            thumbnailAvailable: false,
            title: item?.file[key]["name"],
            use_case: item?.category,
          };
        }

        if (
          videosObj?.ContentType &&
          videosObj?.title &&
          videosObj?.title !== "item"
        ) {
          uploadVideos.push(videosObj);
        }
      }
    }
  );
  return uploadVideos;
};
