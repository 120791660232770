import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { useNavigate } from "react-router-dom";
import classes from "./Dashboard.module.scss";
interface AdminSidebarListProps {
  data: {
    tab_name: string;
    permalink: string;
    icon: React.FunctionComponent<SvgIconProps>;
  };
}

const editingPagesRoutes = ["/edit-page", "/edit-post"];

export default function AdminSidebarList({ data }: AdminSidebarListProps) {
  const location = useLocation();

  const [permalinkState, setPermalinkState] = useState("");
  const [isEditingPage, setIsEditingPage] = useState(
    editingPagesRoutes.some((elem) => location.pathname.indexOf(elem) > 0)
  );

  useEffect(() => {
    setIsEditingPage(
      editingPagesRoutes.some((elem) => location.pathname.indexOf(elem) > 0)
    );
  }, [location.pathname]);
  const navigate = useNavigate();

  const redirectToLink = (link: string) =>
    navigate(`/${link || permalinkState}`);

  return (
    <div
      className={`${
        location.pathname.includes(data.permalink) ? classes.activeTab : ""
      }`}
    >
      <data.icon
        onClick={() => {
          if (data.permalink) {
            if (isEditingPage) {
              setPermalinkState(data.permalink);
              return;
            }
            redirectToLink(data.permalink);
            return;
          }
        }}
      />
    </div>
  );
}
