import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  PreloadedState,
} from "@reduxjs/toolkit";
import authSlice from "../features/Auth/authSlice";
import loginConnectWebsiteSlice from "../features/Login/ConnectMember/LoginConnectWebsite/LoginConnectWebsiteSlice";
import DashboardHomeSlice from "../features/templates/DashboardHome/DashboardHomeSlice";
import StoriesPageSlice from "../features/Dashboard/StoriesPage/Slices/StoriesPageSlice/StoriesPageSlice";
import AddVideoPageSlice from "../features/Dashboard/StoriesPage/Slices/AddVideoPageSlice/AddVideoPageSlice";
import editStorySlice from "../features/Dashboard/StoriesPage/Slices/EditStory/EditStorySlice";
import editVideoScreenSlice from "../features/Dashboard/StoriesPage/Slices/EditVideoScreen/EditVideoScreenSlice"
import ProductPageSlice from "../features/Dashboard/StoriesPage/Slices/ProductPageSlice/ProductPageSlice";

const rootReducer = combineReducers({
  userStore: authSlice,
  linkStore: loginConnectWebsiteSlice,
  ecommStore: DashboardHomeSlice,
  productStore: StoriesPageSlice,
  videoStore: AddVideoPageSlice,
  editStore: editStorySlice,
  videoActionStore:editVideoScreenSlice,
  newStoryStore:ProductPageSlice,
});

export function setupStore(preloadedState?: PreloadedState<RootState>): any {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
