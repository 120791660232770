import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SvgIconProps } from "@mui/material/SvgIcon";
export interface SidebarItem {
  icon: React.FunctionComponent<SvgIconProps>;
  tab_name: string;
  permalink: string;
}


export const sidebarItemsData: SidebarItem [] = [
  {
    icon: HomeOutlinedIcon,
    tab_name: "Home",
    permalink: "dashboard/home",
    
  },
  {
    icon: ListOutlinedIcon,
    tab_name: "Stories",
    permalink: "dashboard/stories",
  },
  {
    icon: ShowChartOutlinedIcon,
    tab_name: "Analytics",
    permalink: "dashboard/analytics",
  },
  {
    icon: InfoOutlinedIcon,
    tab_name: "Info",
    permalink: "dashboard/info",
  },
];

export const Products = {
	config: {
		columns: [
			{ key: "select", label: "Select" },
			{ key: "productName", label: "Product Name" },
			{ key: "product", label: "Product" },
			{ key: "productId", label: "Product ID" },
			{ key: "productUrl", label: "Product URL" },
		],
	},
}

export const EditStories = {
  config: {
		columns: [
			{ key: "drag", label: "" },
			{ key: "video", label: "Video" },
			{ key: "videoTitle", label: "Enter Video Title" },
			{ key: "useCase", label: "Use Case" },
			{ key: "delete", label: "Delete" },
		],
	},
}