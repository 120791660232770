import React from "react";
import classes from "../scss/Common.module.scss";
import { TableRow } from "@mui/material";

type DataItem = {
  [key: string]: any;
};

type ColumnItem = {
  key: string;
  label?: string;
  option?: { value: string; label: string }[];
  type?: string;
};

type ProductRowProps = {
  data: DataItem;
  columns: ColumnItem[];
  selectedProductId: number;
  handleCheckboxChange: Function;
};

function ProductTableRow({
  data,
  selectedProductId,
  handleCheckboxChange,
}: ProductRowProps) {

  return (
    <TableRow className={classes.productTableRow}>
      <td style={{ position: "relative", left: "2.5vw" }}>
        <input
          type="checkbox"
          checked={selectedProductId === data._id}
          onChange={() => {
            handleCheckboxChange(data._id);
          }}
          className={classes.rowCheckbox}
        />
      </td>
      <td className={classes.productTitle}>{data.title}</td>
      <td >
        {data?.meta?.image?.src ? <img
          src={data?.meta?.image?.src}
          alt=""
          className={classes.productImage}
        />:<div  className={classes.productNoImage}>N/A</div>}
        
      </td>
      <td className={classes.productID}>{data.product_id}</td>
      <td className={classes.productUrl}>N/A</td>
    </TableRow>
  );
}

export default ProductTableRow;
