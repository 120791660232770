import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

export default function ScrollToTop (): null {
  const location = useLocation()

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_KEY!)
  }, [location.pathname, location.search])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return null
}
