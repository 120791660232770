import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import API from "../../../../../app/index"
import { RootState } from '../../../../../app/store';
import { showToast } from '../../../../../utils/ErrorMessage';
export interface ProductPageState {
    story:any;
    status: 'idle' | 'loading' | 'failed';
  }
  
  const initialState: ProductPageState = {
    story:null,
    status: 'idle',
  };

export const createStory = createAsyncThunk(
    'widget/newStory',
    async ({name, description,store,product} : {name : string, description : string,store:string,product:number}) => {
        const url = `${process.env.REACT_APP_BASE_URL}/widget/story`
        try{
          const response = await API.post(url,{name, description,store,product})
          // The value we return becomes the `fulfilled` action payload
          showToast("success", "New Story Created Successfully");
          return response.data;
        }catch(err:any){
          console.log(err);
          showToast("error", err.response.data.message);
        }
    }
  );

  export const productPageSlice = createSlice({
    name: 'productPage',
    initialState,
    reducers: {
      createStory: (state : ProductPageState, action : PayloadAction) => {
        state.story = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(createStory.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(createStory.fulfilled, (state, action) => {
          state.status = 'idle';
          state.story = action.payload;
        })
        .addCase(createStory.rejected, (state) => {
          state.status = 'failed';
        });
    },
  });

export const selectProductPageState = (state: RootState) => state.newStoryStore.story;
export default productPageSlice.reducer