import React, { useState, useEffect } from "react";
import classes from "../scss/Common.module.scss";
import AlertDialog from "../AlertDialog";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  deleteVideo,
  selectEditVideoScreenState,
} from "../../Dashboard/StoriesPage/Slices/EditVideoScreen/EditVideoScreenSlice";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";

import {
  uploadThumbnailVideo,
  uploadThumbnail,
} from "../../Dashboard/StoriesPage/Slices/AddVideoPageSlice/AddVideoPageSlice";
import { EditStoryTableRow } from "./EditStoryTableRow";

type DataItem = {
  gif: any;
  thumbnailUploaded: boolean;
  thumbnailReplaced: boolean;
  thumbnailVideoUpload: boolean;
  thumbnailVideoReplace: boolean;
  thumbnailUrl: string;
  _id: string;
  name: string;
  title: string;
  use_case: string;
  deleted: boolean;
};

type StoryEditTableProp = {
  videos: DataItem[];
  setVideos: React.Dispatch<React.SetStateAction<DataItem[]>>;
  getAllMediaList: Function;
  disableRow: boolean;
  setDisableRow: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteId: React.Dispatch<React.SetStateAction<any>>;
  deleteId: any;
};

function StoryEditTable({
  videos,
  setVideos,
  getAllMediaList,
  disableRow,
  setDisableRow,
  setDeleteId,
  deleteId,
}: StoryEditTableProp) {
  const dispatch = useAppDispatch();
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [gif, setGif] = useState<File | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const deleteaction = useAppSelector(selectEditVideoScreenState);

  const handleDragStart = (
    e: React.DragEvent<HTMLTableRowElement>,
    index: number
  ) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLTableRowElement>,
    index: number
  ) => {
    e.preventDefault();
  };

  const handleDrop = (
    e: React.DragEvent<HTMLTableRowElement>,
    index: number
  ) => {
    const fromIndex = Number(e.dataTransfer.getData("index"));
    const newData = [...videos];
    const [removed] = newData.splice(fromIndex, 1);
    newData.splice(index, 0, removed);
    setVideos(newData);
  };

  const handleDeleteOpen = (_id: string) => {
    setDeleteDialog(true);
    setDeleteId((prev: any) => [...prev, { delete: true, _id: _id }]);
  };

  const handleDeleteClose = () => {
    setDeleteDialog(false);
    setDeleteId((prev: any) => {
      const newDeleteIds = [...prev];
      newDeleteIds.pop();
      return newDeleteIds;
    });
  };

  const handleDeleteVideo = () => {
    setDisableRow(true);
    setDeleteDialog(false);
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files![0];
    setThumbnail(selectedFile);
  };

  const handleGifFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files![0];
    setGif(selectedFile);
  };

  useEffect(() => {
    if (thumbnail) {
      dispatch(
        uploadThumbnail({
          _id: selectedVideo!,
          ContentType: thumbnail?.type,
          extension: thumbnail?.type?.split("/")[1],
          file: thumbnail,
          videos,
          setVideos,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, thumbnail]);

  useEffect(() => {
    if (gif) {
      dispatch(
        uploadThumbnailVideo({
          _id: selectedVideo!,
          ContentType: gif?.type,
          extension: gif?.type?.split("/")[1],
          file: gif,
          videos,
          setVideos,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, gif]);

  return (
    <TableContainer
      component={Paper}
      style={{ overflowX: "hidden", maxHeight: "52.54vh" }}
    >
      <Table style={{ width: "max-content", borderSpacing: "0 10px" }}>
        <TableHead>
          <TableRow className={classes.tableHeaderStyle}>
            <TableCell className={classes.tableHeadStyle} />
            <TableCell className={classes.tableHeadStyle}>Video</TableCell>
            <TableCell className={classes.tableHeadStyle}>
              Enter Video Title
            </TableCell>
            <TableCell className={classes.tableHeadStyle}>Use Case</TableCell>
            <TableCell className={classes.tableHeadStyle}>
              Video Thumbnail
            </TableCell>
            <TableCell className={classes.tableHeadStyle}>
              Thumbnail Video
            </TableCell>
            <TableCell className={classes.tableHeadStyle}>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="text-capitalize">
          {videos &&
            videos?.map((video: any, index: number) => {
              return (
                <EditStoryTableRow
                  videos={videos}
                  setVideos={setVideos}
                  video={video}
                  handleDragStart={handleDragStart}
                  handleDragOver={handleDragOver}
                  handleDrop={handleDrop}
                  index={index}
                  setSelectedVideo={setSelectedVideo}
                  handleFileChange={handleFileChange}
                  handleGifFileChange={handleGifFileChange}
                  handleDeleteOpen={() => handleDeleteOpen(video._id)}
                  disableRow={disableRow}
                  deleteId={deleteId}
                />
              );
            })}
        </TableBody>
      </Table>
      {deleteDialog && (
        <AlertDialog
          deleteDialog={deleteDialog}
          handleDeleteClose={handleDeleteClose}
          handleDeleteVideo={handleDeleteVideo}
        />
      )}
    </TableContainer>
  );
}

export default StoryEditTable;
