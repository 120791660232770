import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch, SetStateAction } from "react";
import API from "../../../app/index";
import { RootState } from "../../../app/store";
import { DashboardHomeState } from "../types/Home.type";
import { showToast } from "../../../utils/ErrorMessage";

const initialState: DashboardHomeState = {
  store: null,
  status: "idle",
  recent: null,
};

export const getStore = createAsyncThunk("widget/getStore", async () => {
  const url = `${process.env.REACT_APP_BASE_URL}/widget/store`;
  try {
    const response = await API.get(url);
    if (response.status === 200) {
      localStorage.setItem("storeInfo", JSON.stringify(response.data));
      return response.data;
    }
  } catch (err: any) {
    console.log(err);
    showToast("error", err.response.message);
  }
});

export const getDashboard = createAsyncThunk(
  "widget/story",
  async ({
    setLoading,
    status,
    setTotal,
    skip = 0,
    setSkip,
  }: {
    setLoading?: Dispatch<SetStateAction<boolean>>;
    status?: string;
    setTotal?: Dispatch<SetStateAction<number>>;
    skip?: number;
    setSkip?: Dispatch<SetStateAction<number>>;
  }) => {
    let url = `${process.env.REACT_APP_BASE_URL}/widget/story?limit=10&skip=${skip}`;
    if (status === "Live" || status === "Draft") {
      url = url + `&status=${status}`;
    }
    try {
      const response = await API.get(url);
      if (response.status === 200) {
        setLoading && setLoading(false);
        setSkip && setSkip((p) => p + 10);
        if (setTotal) {
          setTotal(response.data.count);
        }
        return response.data.data;
      }
    } catch (err: any) {
      console.log(err);
      showToast("error", err.response.data.message);
      setLoading && setLoading(false);
    }
  }
);

export const changeStatus = createAsyncThunk(
  "widget/story/status",
  async (
    {
      id,
      status,
      setClick,
    }: {
      id: string;
      status: string;
      setClick:Dispatch<SetStateAction<boolean>>;
    },
    { dispatch }
  ) => {
    let url = `${process.env.REACT_APP_BASE_URL}/widget/story/${id}/${status}`;
    try {
      const response = await API.patch(url);
      if (response.status === 200) {
        setClick && setClick(true);
        localStorage.setItem("statusInfo", JSON.stringify(status));
        showToast("success", "Story Status Changed Successfully");
        dispatch(getDashboard({status}));
      }
    } catch (err: any) {
      console.log(err);
      showToast("error", err.response.data.message);
    }
  }
);

export const dashboardHomeSlice = createSlice({
  name: "dashboardHome",
  initialState,
  reducers: {
    getStore: (state: DashboardHomeState, action: PayloadAction) => {
      state.store = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStore.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStore.fulfilled, (state, action) => {
        state.status = "idle";
        state.store = action.payload;
      })
      .addCase(getStore.rejected, (state) => {
        state.status = "failed";
      });
    builder.addCase(getDashboard.fulfilled, (state, action) => {
      state.recent = action.payload;
    });
  },
});

export const selectDashboardHomeState = (state: RootState) => state.ecommStore;
export const selectDashboardRecentState = (state: RootState) =>
  state.ecommStore.recent;
export default dashboardHomeSlice.reducer;
