import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../../../../app/index";
import axios from "axios";
import { RootState } from "../../../../../app/store";

export interface AddVideoPageState {
  videos: any;
  status: "idle" | "loading" | "failed";
  mediaList: any;
  thumbnail: any;
}

const initialState: AddVideoPageState = {
  videos: null,
  status: "idle",
  mediaList: null,
  thumbnail: null,
};

export const uploadVideo = createAsyncThunk(
  "upload/video",
  async ({
    payload,
    product,
    categoryState,
    setUploadFiles,
    setProgress,
    selectedStory,
    progress
  }: {
    product: number;
    payload: any;
    categoryState: any;
    setUploadFiles: any;
    setProgress: React.Dispatch<React.SetStateAction<any>>;
    selectedStory:string | null;
    progress:any
  }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/widget/media/${selectedStory}`;
    const files = categoryState;
    try {
      await API.post(url, payload).then((res) => {
        res.data.map(async (uploadUrl: any, index: number) => {
          const videoUrl = `${uploadUrl.uploadSignedUrl}`;
          const uploadedFile = files[0].file[index];
          let file: any;
          let percent: number;

          if (files[0].file[index].size > 150 * 1024 * 1024) {
            alert(
              "Error!!!Wrong video format or file size exceeded maximum Limit"
            );
          } else {
            file = files[0].file[index];
          }
          setUploadFiles((prev: any) => [
            ...prev,
            { uploadedFile, status: "incomplete" },
          ]);
          setProgress((prev:any)=>[...prev,{mediaId:uploadUrl?._id,progress:0,type:uploadUrl?.use_case}])
          
          await axios
            .put(videoUrl, file, {
              headers: {
                "Content-Type": file.type,
              },
              onUploadProgress: (progressEvent: {
                loaded: number;
                total?: any;
              }) => {
                const { loaded, total } = progressEvent;
                percent = Math.ceil((loaded * 100) / total);

                
                setProgress((prev:any)=> {
                  let index= prev?.findIndex((value:any)=> { 
                    return value?.mediaId===uploadUrl?._id;
                  });
                  if(index!==-1){
                    let temp=[...prev];
                    temp[index].progress=percent;
                    const unboxing_Array =temp.filter((obj)=> obj.type==="Unboxing")
                    const specs_Array =temp.filter((obj)=> obj.type==="Specs")
                    const review_Array =temp.filter((obj)=> obj.type==="Expert Reviews")
                    return [...unboxing_Array,...specs_Array,...review_Array];
                  }
                  
                  return prev;
                });
              },
            })
            .then((responses) => {
              const patchUrl = `${process.env.REACT_APP_BASE_URL}/widget/media/${uploadUrl?._id}`;
              API.patch(patchUrl).then((response) => {
                try {
                  if (response.data.status === "complete") {
                    setUploadFiles((prev: any) => {
                      let index = prev?.findIndex(
                        (value: any) =>
                          value?.uploadedFile?.name === response?.data?.title
                      );
                      if (index !== false) {
                        let temp = [...prev];
                        temp[index].status = "complete";
                        return prev;
                      }
                      return prev;
                    });
                  }

                  return response;
                } catch (err) {
                  console.log(err);
                }
              });
            });
        });
      });
    } catch (err) {
      console.log(err);
    }
  }
);

export const getAllMedia = createAsyncThunk(
  "widget/media",
  async ({ _id }: { _id: string | null }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/widget/story/?id=${_id}`;
    const response = await API.get(url);
    // The value we return becomes the `fulfilled` action payload

    return response.data;
  }
);

export const uploadThumbnail = createAsyncThunk(
  "widget/thumbnail",
  async ({
    _id,
    ContentType,
    extension,
    file,
    videos,
    setVideos
  }: {
    _id: string | undefined;
    ContentType: string;
    extension: string;
    file: File;
    videos:any,
    setVideos:React.Dispatch<React.SetStateAction<any>>
  }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/widget/media/thumbnail/${_id}?ContentType=${ContentType}&extension=${extension}&gif=false`;
    try {
      await API.post(url).then((res) => {
        const preSignedUrl = res?.data?.thumbnailUploadUrl;
        axios
          .put(preSignedUrl, file, {
            headers: {
              "Content-Type": file?.type,
            },
          })
          .then(async (responses) => {
            const patchUrl = `${process.env.REACT_APP_BASE_URL}/widget/media/${_id}`;
            await API.patch(patchUrl).then((response) => {
              try {
                videos && videos.map((video:any,index:number)=>{
                  if(video._id===response.data._id){
                    let videoObj = { ...video, thumbnailUploaded: true };
                        let list = [...videos];
                        list[index] = videoObj;
                        setVideos(list);
                  }
                  if(video.thumbnailUploaded && video._id===response.data._id){
                    const videoObj = { ...video, thumbnailReplaced: true };
                        const list = [...videos];
                        list[index] = videoObj;
                        setVideos(list);
                  }
                })
              } catch (err) {
                console.log(err);
              }
            });
          });
      });
    } catch (err) {
      console.log(err);
    }
  }
);

export const uploadThumbnailVideo = createAsyncThunk(
  "widget/uploadGif",
  async ({
    _id,
    file,
    ContentType,
    extension,
    videos,
    setVideos
  }: {
    _id: string | undefined;
    ContentType: string;
    extension: string;
    file: File;
    videos:any,
    setVideos:React.Dispatch<React.SetStateAction<any>>
  }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/widget/media/thumbnail/${_id}?ContentType=${ContentType}&extension=${extension}&gif=true`;
    try {
      await API.post(url).then((res) => {
        const preSignedUrl = res?.data?.gif?.uploadSignedUrl;
        axios
          .put(preSignedUrl, file, {
            headers: {
              "Content-Type": file?.type,
            },
          })
          .then(async (responses) => {
            const patchUrl = `${process.env.REACT_APP_BASE_URL}/widget/media/${_id}`;
            await API.patch(patchUrl).then((response) => {
              try {
                videos &&
                  videos.forEach((video: any, index: number) => {
                    if (video?._id === response?.data?._id) {
                      let videoObj = { ...video, thumbnailVideoUpload: true };
                      let list = [...videos];
                      list[index] = videoObj;
                      setVideos(list);
                    }
                    if (
                      video?.thumbnailVideoUpload &&
                      video?._id === response?.data?._id
                    ) {
                      const videoObj = { ...video, thumbnailVideoReplace: true };
                      const list = [...videos];
                      list[index] = videoObj;
                      setVideos(list);
                    }
                  });
              } catch (err) {
                console.log(err);
              }
            });
          });
      });
    } catch (err) {
      console.log(err);
    }
  }
);



export const addVideoPageSlice = createSlice({
  name: "addVideoPage",
  initialState,
  reducers: {
    uploadVideo: (state: AddVideoPageState, action: PayloadAction) => {
      state.videos = action.payload;
    },
    getAllMedia: (state: AddVideoPageState, action: PayloadAction) => {
      state.mediaList = action.payload;
    },
    uploadThumbnail: (state: AddVideoPageState, action: PayloadAction) => {
      state.thumbnail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadVideo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadVideo.fulfilled, (state, action) => {
        state.status = "idle";
        state.videos = action.payload;
      })
      .addCase(uploadVideo.rejected, (state) => {
        state.status = "failed";
      });
    builder
      .addCase(getAllMedia.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllMedia.fulfilled, (state, action) => {
        state.status = "idle";
        state.mediaList = action.payload;
      })
      .addCase(getAllMedia.rejected, (state) => {
        state.status = "failed";
      });
    builder
      .addCase(uploadThumbnail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadThumbnail.fulfilled, (state, action) => {
        state.status = "idle";
        state.thumbnail = action.payload;
      })
      .addCase(uploadThumbnail.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectAddVideoPageState = (state: RootState) => state.videoStore;
export const selectVideoThumbnailState = (state: RootState) =>
  state.videoStore.thumbnail;
export default addVideoPageSlice.reducer;
