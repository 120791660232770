import { AsyncThunkAction } from "@reduxjs/toolkit";
import { uploadVideo } from "../features/Dashboard/StoriesPage/Slices/AddVideoPageSlice/AddVideoPageSlice";

interface CategoryObject {
  file: FileList;
  category: string;
}
interface uploadFile {
  status: string;
  uploadedFile: File;
}
export const makeVideoDispatch = async (
  dispatch: (
    arg0: AsyncThunkAction<any, { product: number; payload: any }, {}>
  ) => void,
  videosObj:  {
    ContentType: string;
    extension?: string;
    thumbnailContentType?: string | undefined;
    thumbnailExtension?: string | undefined;
    thumbnailAvailable?: boolean | undefined;
    title: string;
    use_case?: string;
}[],
  productId: number,
  categoryState: CategoryObject[],
  setUploadFiles:React.Dispatch<React.SetStateAction<uploadFile[]>>,
  setProgress:React.Dispatch<React.SetStateAction<any>>,
  selectedStory:string | null,
  progress:any
) => {
  if (videosObj.length !== 0) {
    dispatch(
      uploadVideo({
        payload: videosObj,
        product: productId,
        categoryState: categoryState,
        setUploadFiles,
        setProgress,
        selectedStory,
        progress
      })
    );
  }
};
