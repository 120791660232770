export const getMediaList = (
  videos: any,
  updateState: any,
  productId: number,
  deleteIdData: any
) => {
  let UpdateMediaObj: { _id: any; delete: any; update: any };
  let updateMediaData: { _id: any; delete: any; update: any }[] = [];
  let updatedRowObj;
  let storyObj;
  let draftStoryObj;
  let mediaId: any[] = [];

  updateState &&
    updateState.forEach((item: any, index: any) => {
      UpdateMediaObj = {
        _id: item?._id,
        delete: deleteIdData.find((obj: any) => obj._id === item._id)
          ? true
          : false,
        update: {
          enabled: item?.enabled,
          title: item?.newTitle,
          use_case: item?.newUse_Case,
        },
      };

      mediaId.push(item._id);

      updateMediaData.push(UpdateMediaObj);

      updatedRowObj = {
        _id: productId,
        update: {
          media: updateMediaData
            .map((obj) => {
              if (obj.delete === false) {
                return obj._id;
              } else {
                return undefined;
              }
            })
            .filter(Boolean),
        },
      };
    });

  storyObj = {
    _id: videos?._id,
    update: {
      status: "Live",
      media: updateMediaData
        .map((obj) => {
          if (obj.delete === false) {
            return obj._id;
          } else {
            return undefined;
          }
        })
        .filter(Boolean),
    },
  };
  draftStoryObj = {
    _id: videos?._id,
    update: {
      status: "Draft",
      media: updateMediaData
        .map((obj) => {
          if (obj.delete === false) {
            return obj._id;
          } else {
            return undefined;
          }
        })
        .filter(Boolean),
    },
  };

  return { updateMediaData, storyObj, updatedRowObj, draftStoryObj };
};
